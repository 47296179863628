import React from 'react';
import '../css/header.css'

const Header = () => {
  return (
    <div class="headerDiv">
      <h1>USA</h1> <h1>Fitness</h1>
    </div>
  )
}

export default Header
